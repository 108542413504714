import { IConsolidatedItemListCatalog, ItemListCatalog } from "./ItemListCatalog";
import { IConsolidatedItemListInfo, ItemListInfo } from "./ItemListInfo";
import { IConsolidatedItemListItem, IConsolidatedItemListSubItem, ItemListItem } from "./ItemListItem";
import { IConsolidatedItemListMessage, ItemListMessage } from "./ItemListMessage";
import { ItemListProviderPrice } from "./ItemListProviderPrice";

export class CommercialItemListFactory {
    static create(commercialItemList: ICommercialItemList): IConsolidatedCommercialItemList {
        return new CommercialItemList(commercialItemList).serialize();
    }
}

export class CommercialItemList {
    protected _items?: Array<ItemListItem>;
    protected _info: ItemListInfo;
    protected _catalogs?: Array<ItemListCatalog>;
    // protected _groups?: Array<IItemListGroup>;           // TODO: Implement when defined
    protected _extras?: string;
    protected _messages?: Array<ItemListMessage>;

    constructor(commercialItemList: ICommercialItemList) {
        this._info = new ItemListInfo(commercialItemList.info);
        this._extras = commercialItemList.extras;

        const
            warnings: Array<ItemListMessage> | undefined = commercialItemList.warnings?.map((warning: IItemListMessage) => new ItemListMessage(warning)),
            notes: Array<ItemListMessage> | undefined = commercialItemList.notes?.map((note: IItemListMessage) => new ItemListMessage(note)),
            errors: Array<ItemListMessage> | undefined = commercialItemList.errors?.map((error: IItemListMessage) => new ItemListMessage(error)),
            allMessages: Array<ItemListMessage> = [...(errors ?? []), ...(warnings ?? []), ...(notes ?? [])];

        this._messages = _getMessagesFor("itemList", undefined, allMessages);

        this._items = commercialItemList.items?.map((item: IItemListItem) => {
            const itemMessages: Array<ItemListMessage> = _getMessagesFor("item", item.id, allMessages);
            return new ItemListItem(item, itemMessages);
        });

        if (this._items?.length) {
            _updateSubItemCodes(this._items);
        }

        this._catalogs = commercialItemList.catalogs?.map((catalog: IItemListCatalog) => {
            let catalogId: string = catalog.id!,
                providerPrices: Array<IItemListProviderPrice> | undefined = commercialItemList.totals?.find((total: IItemListTotal) => total.catalogId === catalogId)?.providerPrices,
                totals: Array<ItemListProviderPrice> | undefined = providerPrices?.map((price: IItemListProviderPrice) => new ItemListProviderPrice(price)),
                integrations: Array<IItemListIntegration> | undefined = commercialItemList.integrations?.filter((integration: IItemListIntegration) => integration.id === catalogId),
                itemIds: Array<string> | undefined = commercialItemList.items?.filter((item: IItemListItem) => item.catalogItemRef.catalogId === catalogId).map((item: IItemListItem) => item.id),
                catalogMessages: Array<ItemListMessage> = _getMessagesFor("catalog", catalogId, allMessages);

            return new ItemListCatalog(catalog, totals, integrations, itemIds, catalogMessages);
        });
    }

    serialize(): IConsolidatedCommercialItemList {
        const commercialItemList: IConsolidatedCommercialItemList = {
            info: this._info.serialize()
        };

        if (this._items !== undefined) {
            commercialItemList.items = this._items.map((item: ItemListItem) => item.serialize());
        }

        if (this._catalogs !== undefined) {
            commercialItemList.catalogs = this._catalogs.map((catalog: ItemListCatalog) => catalog.serialize());
        }

        if (this._messages !== undefined) {
            commercialItemList.messages = this._messages.map((message: ItemListMessage) => message.serialize());
        }

        if (this._extras !== undefined) {
            commercialItemList.extras = this._extras;
        }

        return commercialItemList;
    }
}

export interface IConsolidatedCommercialItemList {
    info: IConsolidatedItemListInfo;
    items?: Array<IConsolidatedItemListItem>;
    catalogs?: Array<IConsolidatedItemListCatalog>;
    messages?: Array<IConsolidatedItemListMessage>;
    extras?: string;
}

function _updateSubItemCodes(items: Array<ItemListItem>) {
    const itemsIdCodeMap: Map<string, string> = new Map(items.map((item: ItemListItem) => [item.id, item.primaryRefCode]));

    items.forEach((item: ItemListItem) => {
        item.subItems?.forEach((subItem: IConsolidatedItemListSubItem) => {
            if (itemsIdCodeMap.has(subItem.itemId)) {
                subItem.code = itemsIdCodeMap.get(subItem.itemId);
            }
        });
    });
}

function _getMessagesFor(type: "item" | "catalog" | "itemList", id: string | undefined, allMessages: Array<ItemListMessage>): Array<ItemListMessage> {
    let messages: Array<ItemListMessage> = [];

    if (type === "itemList") {
        messages = allMessages.slice();
    } else if (type === "item") {
        messages = allMessages.filter((message: ItemListMessage) => message.commercialItemId === id);
    } else if (type === "catalog") {
        messages = allMessages.filter((message: ItemListMessage) => message.catalogId === id);
    }

    return messages;
}