import { PricingErrorMessagesMap } from "@cic/utils";
export type ItemListMessageType = "Error" | "Warning" | "Information";

export class ItemListMessageFactory {
    static create(itemListMessage: IItemListMessage): IConsolidatedItemListMessage {
        return new ItemListMessage(itemListMessage).serialize();
    }
}

export class ItemListMessage {
    protected _itemId?: string;
    protected _code: string;
    protected _details: string;
    protected _extraDetails?: unknown;
    protected _catalogId?: string;
    protected _type: ItemListMessageType;

    constructor(itemListMessage: IItemListMessage) {
        this._itemId = itemListMessage.commercialItemId;
        this._code = itemListMessage.code;
        this._extraDetails = itemListMessage.details;
        this._catalogId = itemListMessage.catalogId;
        this._type = itemListMessage.type as ItemListMessageType;
        this._details = "";

        if (PricingErrorMessagesMap.has(this._code)) {
            this._details = PricingErrorMessagesMap.get(this._code) ?? "";
        }
    }

    get commercialItemId(): string | undefined {
        return this._itemId;
    }

    get catalogId(): string | undefined {
        return this._catalogId;
    }

    serialize(): IConsolidatedItemListMessage {
        const itemListMessage: IConsolidatedItemListMessage = {
            type: this._type,
            code: this._code,
            details: this._details,
            commercialItemId: this._itemId,
            catalogId: this._catalogId
        };

        if (this._extraDetails !== undefined) {
            itemListMessage.extraDetails = this._extraDetails;
        }

        return itemListMessage;
    }
}

export interface IConsolidatedItemListMessage {
    type: ItemListMessageType;
    code: string;
    details?: string;
    extraDetails?: unknown;
    commercialItemId?: string;
    catalogId?: string;
}