import { IConsolidatedItemListMessage, ItemListMessage } from "./ItemListMessage";
import { IConsolidatedItemListProviderPrice, ItemListProviderPrice } from "./ItemListProviderPrice";

export class ItemListCatalogFactory {
    static create(itemListCatalog: IItemListCatalog, totals?: Array<ItemListProviderPrice>, integrations?: Array<unknown>, itemIds?: Array<string>, messages?: Array<ItemListMessage>): IConsolidatedItemListCatalog {
        return new ItemListCatalog(itemListCatalog, totals, integrations, itemIds, messages).serialize();
    }
}

export class ItemListCatalog {
    protected _id?: string;
    protected _name?: string;
    protected _code?: string;
    protected _providerCompanyCode?: string;
    protected _providerCompanyName?: string;
    protected _providerType?: CatalogProviderType;
    protected _source?: ItemListCatalogSource;
    protected _version?: string;
    protected _note?: string;
    protected _integrations?: Array<unknown>;
    protected _totals?: Array<ItemListProviderPrice>;
    protected _itemIds?: Array<string>;
    protected _messages?: Array<ItemListMessage>;


    constructor(itemListCatalog: IItemListCatalog, totals?: Array<ItemListProviderPrice>, integrations?: Array<unknown>, itemIds?: Array<string>, messages?: Array<ItemListMessage>) {
        this._id = itemListCatalog.id;
        this._name = itemListCatalog.name;
        this._code = itemListCatalog.code;
        this._providerCompanyCode = itemListCatalog.provider?.companyCode;
        this._providerCompanyName = itemListCatalog.provider?.name;
        this._providerType = itemListCatalog.provider?.type;
        this._source = itemListCatalog.source;
        this._version = itemListCatalog.version;
        this._note = itemListCatalog.note;
        this._integrations = integrations;
        this._totals = totals;
        this._itemIds = itemIds;
        this._messages = messages;
    }

    serialize(): IConsolidatedItemListCatalog {
        const itemListCatalog: IConsolidatedItemListCatalog = {
            id: this._id,
            name: this._name,
            code: this._code
        };

        if (this._providerCompanyCode !== undefined) {
            itemListCatalog.companyCode = this._providerCompanyCode;
        }

        if (this._providerCompanyName !== undefined) {
            itemListCatalog.companyName = this._providerCompanyName;
        }

        if (this._providerType !== undefined) {
            itemListCatalog.providerType = this._providerType;
        }

        if (this._source !== undefined) {
            itemListCatalog.source = this._source;
        }

        if (this._version !== undefined) {
            itemListCatalog.version = this._version;
        }

        if (this._note !== undefined) {
            itemListCatalog.note = this._note;
        }

        if (this._totals !== undefined) {
            itemListCatalog.totals = this._totals?.map((total: ItemListProviderPrice) => total.serialize());
        }

        if (this._itemIds !== undefined) {
            itemListCatalog.itemIds = this._itemIds;
        }

        if (this._integrations !== undefined) {
            itemListCatalog.integrations = this._integrations?.map((integration: unknown) => (typeof integration === "string") ? JSON.parse(integration as string) : integration);
        }

        if (this._messages !== undefined) {
            itemListCatalog.messages = this._messages.map((message: ItemListMessage) => message.serialize());
        }

        return itemListCatalog;
    }
}

export interface IConsolidatedItemListCatalog {
    id?: string;
    name?: string;
    code?: string;
    companyCode?: string;
    companyName?: string;
    providerType?: CatalogProviderType;
    source?: ItemListCatalogSource;
    version?: string;
    note?: string;
    totals?: Array<IConsolidatedItemListProviderPrice>;
    itemIds?: Array<string>;
    integrations?: Array<Object>;
    messages?: Array<IConsolidatedItemListMessage>;
}