import { IConsolidatedItemListCalculationStepCustomFunction, ItemListCalculationStepCustomFunction } from "./ItemListCalculationStepsCustomFunction";

export class ItemListCalculationStepFactory {
    static create(itemListCalculationSteps: IItemListCalculationStep): IConsolidatedItemListCalculationStep {
        return new ItemListCalculationStep(itemListCalculationSteps).serialize();
    }
}

export class ItemListCalculationStep {
    protected _code: string;
    protected _expression: string;
    protected _baseValueType: string;
    protected _runningTotal: number;
    protected _baseValue: number;
    protected _sequence: number;
    protected _fromSequence?: number;
    protected _toSequence?: number;
    protected _calculationSteps?: Array<ItemListCalculationStep>;
    protected _descriptions?: Record<string, string>;
    protected _selectors?: Record<string, string>;
    protected _customFunction?: ItemListCalculationStepCustomFunction;
    protected _value: number;

    constructor(itemListCalculationSteps: IItemListCalculationStep) {
        this._code = itemListCalculationSteps.code;
        this._expression = itemListCalculationSteps.expression;
        this._baseValueType = itemListCalculationSteps.baseValueType;
        this._runningTotal = itemListCalculationSteps.runningTotal;
        this._baseValue = itemListCalculationSteps.baseValue;
        this._sequence = itemListCalculationSteps.sequence;
        this._fromSequence = itemListCalculationSteps.fromSequence;
        this._toSequence = itemListCalculationSteps.toSequence;
        this._calculationSteps = itemListCalculationSteps.calculationSteps?.map((calculationStep: IItemListCalculationStep) => new ItemListCalculationStep(calculationStep));
        this._descriptions = itemListCalculationSteps.descriptions;
        this._selectors = itemListCalculationSteps.selectors;
        this._customFunction = itemListCalculationSteps.customFunction ? new ItemListCalculationStepCustomFunction(itemListCalculationSteps.customFunction) : undefined;
        this._value = itemListCalculationSteps.value;
    }

    serialize(): IConsolidatedItemListCalculationStep {
        const pricingReportItemCalculationSteps: IConsolidatedItemListCalculationStep = {
            code: this._code,
            expression: this._expression,
            baseValueType: this._baseValueType,
            runningTotal: this._runningTotal,
            baseValue: this._baseValue,
            sequence: this._sequence,
            value: this._value
        };

        if (this._fromSequence !== undefined) {
            pricingReportItemCalculationSteps.fromSequence = this._fromSequence;
        }

        if (this._toSequence !== undefined) {
            pricingReportItemCalculationSteps.toSequence = this._toSequence;
        }

        if (this._calculationSteps !== undefined) {
            pricingReportItemCalculationSteps.calculationSteps = this._calculationSteps.map((calculationStep: ItemListCalculationStep) => calculationStep.serialize());
        }

        if (this._descriptions !== undefined) {
            pricingReportItemCalculationSteps.descriptions = this._descriptions;
        }

        if (this._selectors !== undefined) {
            pricingReportItemCalculationSteps.selectors = this._selectors;
        }

        if (this._customFunction !== undefined) {
            pricingReportItemCalculationSteps.customFunction = this._customFunction.serialize();
        }

        return pricingReportItemCalculationSteps;

    }
}

export interface IConsolidatedItemListCalculationStep {
    code: string;
    expression: string;
    baseValueType: string;
    runningTotal: number;
    baseValue: number;
    sequence: number;
    fromSequence?: number;
    toSequence?: number;
    calculationSteps?: Array<IConsolidatedItemListCalculationStep>;
    descriptions?: Record<string, string>;
    selectors?: Record<string, string>;
    customFunction?: IConsolidatedItemListCalculationStepCustomFunction;
    value: number;
}