import { ConfigurationChange, IConsolidatedConfigurationChange } from "./ConfigurationChange";
import { FeatureClassification, IConsolidatedFeatureClassification } from "./FeatureClassification";
import { FeatureOption, IConsolidatedFeatureOption } from "./FeatureOption";
import { FeatureSelection, IConsolidatedFeatureSelection } from "./FeatureSelection";
import { NumericalSpec, IConsolidatedNumericalSpec } from "./NumericalSpec";
import { SearchResult, IConsolidatedSearchResult } from "./SearchResult";

export class FeatureFactory {
    static create(feature: IFeature | V2.IFeature | IConsolidatedFeature): IConsolidatedFeature {
        return new Feature(feature).serialize();
    }
}

export class Feature {
    protected _id: string;
    protected _name: string;
    protected _code: string;
    protected _path: string;
    protected _description?: string;
    protected _defaultOption?: FeatureOption;
    protected _selectedOptions?: Array<FeatureSelection>;
    protected _numericalSpec?: NumericalSpec;
    protected _sequenceFactor?: number;
    protected _selectionType?: SelectionType;
    protected _classification?: FeatureClassification;
    protected _searchResult?: SearchResult;
    protected _visible: boolean;
    protected _invalidationReasons?: Array<ConfigurationChange>;

    constructor(feature: IFeature | V2.IFeature | IConsolidatedFeature) {
        this._id = feature.id;
        this._name = feature.name;
        this._code = feature.code;
        this._path = feature.path;
        this._description = feature.description;
        this._defaultOption = feature.defaultOption ? new FeatureOption(feature.defaultOption) : undefined;
        this._numericalSpec = feature.numericalSpec ? new NumericalSpec(feature.numericalSpec) : undefined;
        this._sequenceFactor = feature.sequenceFactor;
        this._selectionType = feature.selectionType;
        this._classification = feature.classification ? new FeatureClassification(feature.classification) : undefined;
        this._searchResult = feature.searchResult ? new SearchResult(feature.searchResult) : undefined;
        this._visible = feature.visible;
        this._invalidationReasons = feature.invalidationReasons?.map((reason: IConfigurationChange) => new ConfigurationChange(reason));

        let selectedOptionsV2: Array<V2.IFeatureSelection> | undefined = (feature as V2.IFeature).selectedOptions;
        if (selectedOptionsV2) {
            this._selectedOptions = selectedOptionsV2?.map((selectedOption: V2.IFeatureSelection) => new FeatureSelection(selectedOption));
        } else {
            let selectedOptionV1: IFeatureOption | undefined = (feature as IFeature).selectedOption;
            if (selectedOptionV1) {
                this._selectedOptions = [new FeatureSelection({ option: selectedOptionV1 })];
            }
        }
    }

    get id(): string {
        return this._id;
    }

    get name(): string {
        return this._name;
    }

    get code(): string {
        return this._code;
    }

    get path(): string {
        return this._path;
    }

    set path(value: string) {
        this._path = value;
    }

    get description(): string | undefined {
        return this._description;
    }

    get defaultOption(): FeatureOption | undefined {
        return this._defaultOption;
    }

    /** @deprecated use selectedOptions as there can be more than one option selected */
    get selectedOption(): FeatureOption | undefined {
        return this._selectedOptions && this._selectedOptions[0].option;
    }

    get selectedOptions(): Array<FeatureSelection> | undefined {
        return this._selectedOptions;
    }

    set selectedOptions(value: Array<FeatureSelection> | undefined) {
        this._selectedOptions = value;
    }    
    
    get numericalSpec(): NumericalSpec | undefined {
        return this._numericalSpec;
    }

    get sequenceFactor(): number | undefined {
        return this._sequenceFactor;
    }

    get selectionType(): SelectionType | undefined {
        return this._selectionType;
    }

    get classification(): FeatureClassification | undefined {
        return this._classification;
    }

    get searchResult(): SearchResult | undefined {
        return this._searchResult;
    }

    get visible(): boolean {
        return this._visible;
    }

    get invalidationReasons(): Array<ConfigurationChange> | undefined {
        return this._invalidationReasons;
    }

    serialize(): IConsolidatedFeature {
        const feature: IConsolidatedFeature = {
            id: this._id,
            name: this._name,
            code: this._code,
            path: this._path,
            visible: this._visible
        };

        if (this._description !== undefined) {
            feature.description = this._description;
        }

        if (this._defaultOption !== undefined) {
            feature.defaultOption = this._defaultOption.serialize();
        }

        if (this._selectedOptions !== undefined) {
            feature.selectedOptions = this._selectedOptions.map((selectedOption: FeatureSelection) => selectedOption.serialize());
            if (this._selectedOptions.length) {
                feature.selectedOption = this._selectedOptions[0].option.serialize();
            }
        }

        if (this._numericalSpec !== undefined) {
            feature.numericalSpec = this._numericalSpec.serialize();
        }

        if (this._sequenceFactor !== undefined) {
            feature.sequenceFactor = this._sequenceFactor;
        }

        if (this._selectionType !== undefined) {
            feature.selectionType = this._selectionType;
        }

        if (this._classification !== undefined) {
            feature.classification = this._classification.serialize();
        }

        if (this._searchResult !== undefined) {
            feature.searchResult = this._searchResult.serialize();
        }

        if (this._invalidationReasons !== undefined) {
            feature.invalidationReasons = this._invalidationReasons.map((reason: ConfigurationChange) => reason.serialize());
        }

        return feature;
    }
}

export interface IConsolidatedFeature {
    id: string;
    name: string;
    code: string;
    path: string;
    description?: string;
    defaultOption?: IConsolidatedFeatureOption;
    /** @deprecated use selectedOptions as there can be more than one option selected */
    selectedOption?: IConsolidatedFeatureOption;
    selectedOptions?: Array<IConsolidatedFeatureSelection>;
    numericalSpec?: IConsolidatedNumericalSpec;
    sequenceFactor?: number;
    selectionType?: SelectionType;
    classification?: IConsolidatedFeatureClassification;
    searchResult?: IConsolidatedSearchResult;
    visible: boolean;
    invalidationReasons?: Array<IConsolidatedConfigurationChange>;
}