import Header from './components/header/Header';
import Footer from './components/footer/Footer';
import SettingsPanel from './components/footer/SettingsPanel';
import Loader from './components/loader/Loader';
import Overlay from './components/overlay/Overlay';
import DropZone from './components/dropZone/DropZone';
import SlidingPanel, {
    SLIDER_DIRECTION,
} from './components/slidingPanel/SlidingPanel';
import PCSSniffer from './components/pcsSniffer/PCSSniffer';
import SwitchToggle from './components/switchToggle/SwitchToggle';
import SettingsButton from './components/settingsButton/SettingsButton';
import RestrictionsButton from './components/restrictionsButton/RestrictionsButton';
import UIToolkitUtils from './UIToolkitUtils';
import Tabs, { TAB_ORIENTATION } from './components/tabs/Tabs';
import { ITab, ITabDefinitionObject } from "./components/tabs/models/ITab";
import InfiniteScrollTrigger from './components/infiniteScroller/InfiniteScrollTrigger';
import MoreInfoIcon from './components/moreInfoIcon/MoreInfoIcon';
import SingleWorkerCheck from './components/singleWorkerCheck/SingleWorkerCheck';
export {
    CatalogFeatureCard,
    ClassificationCard,
} from './components/catalogFeatureCard';

export { CatalogEntityHeader } from './components/catalogArtifactHeader/CatalogArtifactHeader';
export { ThemeProvider } from './components/themeProvider/ThemeProvider';
export { Modal } from '@cic/design-system';
export { Tag } from './components/tag/Tag';
export { EditAttributesForm } from './components/itemModifications/editAttributesForm';
export { EditLayerForm } from './components/itemModifications/editLayerForm';
export { CatalogOptionCard } from './components/catalogOptionCard/CatalogOptionCard';
export { OptionMenu } from './components/optionMenu/OptionMenu';
export { EditCatalogFeatureForm } from './components/editCatalogFeatureForm/EditCatalogFeatureForm';
export { FormTabs } from './components/formTabs/FormTabs';
export { Tree } from './components/tree/Tree';
export { CollapsableSection } from './components/collapsableSection/CollapsableSection';
export { Table, Row, Cell } from './components/table/Table';
export { TableSkeleton } from './components/table/TableSkeleton';
export { ResolverLogTree } from './components/resolverLogTree/ResolverLogTree';
export { ResolverLogTreeBtn } from './components/resolverLogTree/ResolverLogTreeBtn';
export { CatalogEntityProperty } from './components/catalogEntityProperty/CatalogEntityProperty';
export { Point } from './components/point/Point';
export { FeatureCard } from './components/featureCard';
export { OptionCard } from './components/optionCard';
export { BrowserTabs, type BTab } from './components/browserTabs/BrowserTabs';
export { Search } from './components/search/Search';
export { CatalogSelector } from './components/catalogSelector/CatalogSelector';

export { useItemContextList } from './hooks/useItemContextList';
export { useItemSelection } from './hooks/useItemSelection';
export { useItemVariant } from './hooks/useItemVariant';
export { useDebugItem } from './hooks/useDebugItem';
export { useFocusedItems } from './hooks/useFocusedItems';
export { useRevealItem } from './hooks/useRevealItem';
export { useContextualHelp } from './hooks/useContextualHelp';
export { useItemsVariants } from './hooks/useItemsVariants';
export { useCatalogItems } from './hooks/useCatalogItems';
export { useBrowserTabs } from './hooks/useBrowserTab';
export { useApplicationInsights } from './hooks/useApplicationInsights';
export { useTranslations } from './hooks/useTranslations';
export { Input } from './components/input/Input';

export {
    TabContext,
    type TabAction,
    TabContextProvider,
} from './contexts/tabContext';

const CICAPI_FILENAME: string = 'cicapi.web.js';

//=============================================================================
async function importCiCAPI(url: string) {
    let apiCodeUrl: string = url,
        response: Response,
        code: string;

    if (
        window.location.toString().includes('debug') &&
        !apiCodeUrl.endsWith('/debug/' + CICAPI_FILENAME)
    ) {
        apiCodeUrl = apiCodeUrl.replace(
            CICAPI_FILENAME,
            'debug/' + CICAPI_FILENAME,
        );
    }

    (response = await fetch(apiCodeUrl)), (code = await response.text());
    new Function(code)();
}

export {
    importCiCAPI,
    Header as TestAppHeader,
    Footer as TestAppFooter,
    SettingsPanel,
    Loader,
    Overlay,
    DropZone,
    SlidingPanel,
    Tabs,
    type ITab,
    type ITabDefinitionObject,
    SLIDER_DIRECTION,
    TAB_ORIENTATION,
    PCSSniffer,
    SwitchToggle,
    SettingsButton,
    RestrictionsButton,
    UIToolkitUtils,
    InfiniteScrollTrigger,
    SingleWorkerCheck,
    MoreInfoIcon,
};
