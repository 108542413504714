import { ObjectUtils } from "@cic/utils";
import { IConsolidatedItemListProviderPrice, ItemListProviderPrice } from "./ItemListProviderPrice";
import { IConsolidatedItemListCalculationStep, ItemListCalculationStep } from "./ItemListCalculationSteps";
import { IConsolidatedItemListItemDimensions, ItemListItemDimensions } from "./ItemListItemDimensions";
import { IConsolidatedItemClassification, ItemClassification } from "./ItemClassification";
import { IConsolidatedReferenceCodes, ReferenceCodes } from "./ReferenceCodes";
import { IConsolidatedItemListItemAvailability, ItemListItemAvailability } from "./ItemListItemAvailability";
import { ConfigurationState, FeatureState, IConsolidatedConfigurationState } from "./ConfigurationState";
import { IConsolidatedItemListMessage, ItemListMessage } from "./ItemListMessage";

export class ItemListItemFactory {
    static create(itemListItem: IItemListItem, messages?: Array<ItemListMessage>): IConsolidatedItemListItem {
        return new ItemListItem(itemListItem, messages).serialize();
    }
}

export class ItemListItem {
    protected _id: string;
    protected _primaryRefCode: string;
    protected _catalogId: string;
    protected _itemCode?: string;
    protected _configurationState?: ConfigurationState;
    protected _name?: string;
    protected _description?: string;
    protected _dimensions?: ItemListItemDimensions;
    protected _classification?: ItemClassification;
    protected _refCodes?: ReferenceCodes;
    protected _attributes?: Record<string, Array<ResolvableTypes>>;
    protected _quantity: number;
    protected _quantityPerPack?: number;
    protected _packMeasurementUnit?: ItemListMeasurementUnit;
    protected _providerPrices?: Array<ItemListProviderPrice>;
    protected _note?: string;
    protected _sourceType?: ItemListItemSourceType;
    protected _extras?: string;
    protected _subItems?: Array<IConsolidatedItemListSubItem>;
    protected _availability?: ItemListItemAvailability;
    protected _calculationSteps?: Array<ItemListCalculationStep>;
    protected _messages?: Array<ItemListMessage>;


    constructor(itemListItem: IItemListItem, messages?: Array<ItemListMessage>) {
        this._id = itemListItem.id;
        this._primaryRefCode = itemListItem.primaryRefCode;
        this._catalogId = itemListItem.catalogItemRef.catalogId;
        this._itemCode = itemListItem.catalogItemRef.itemCode;

        if (itemListItem.catalogItemRef.configurationState?.length) {
            this._configurationState = [];
            itemListItem.catalogItemRef.configurationState.forEach((featureState: IFeatureState) => {
                this._configurationState?.push(new FeatureState(featureState));
            });
        }

        this._name = itemListItem.name;
        this._description = itemListItem.description;
        this._dimensions = itemListItem.dimensions ? new ItemListItemDimensions(itemListItem.dimensions) : undefined;
        this._classification = itemListItem.classification ? new ItemClassification(itemListItem.classification) : undefined;
        this._refCodes = itemListItem.refCodes ? new ReferenceCodes(itemListItem.refCodes) : undefined;
        this._attributes = itemListItem.attributes;
        this._quantity = itemListItem.quantity;
        this._quantityPerPack = itemListItem.quantityPerPack;
        this._packMeasurementUnit = itemListItem.packMeasurementUnit;
        this._providerPrices = itemListItem.providerPrices?.map((providerPrice: IItemListProviderPrice) => new ItemListProviderPrice(providerPrice));
        this._note = itemListItem.note;
        this._sourceType = itemListItem.source?.type;
        this._extras = itemListItem.extras;
        this._subItems = itemListItem.subItems;
        this._availability = itemListItem.availability ? new ItemListItemAvailability(itemListItem.availability) : undefined;
        this._calculationSteps = itemListItem.calculationSteps?.map((calculationStep: IItemListCalculationStep) => new ItemListCalculationStep(calculationStep));
        this._messages = messages;
    }

    get id(): string {
        return this._id;
    }

    get primaryRefCode(): string {
        return this._primaryRefCode;
    }

    get subItems(): Array<IConsolidatedItemListSubItem> | undefined {
        return this._subItems;
    }

    serialize(): IConsolidatedItemListItem {
        const itemListItem: IConsolidatedItemListItem = {
            id: this._id,
            primaryRefCode: this._primaryRefCode,
            catalogId: this._catalogId,
            itemCode: this._itemCode,
            quantity: this._quantity,
            quantityPerPack: this._quantityPerPack,
        };

        if (this._configurationState !== undefined) {
            itemListItem.configurationState = this._configurationState.map((featureState: FeatureState) => featureState.serialize());
        }

        if (this._name !== undefined) {
            itemListItem.name = this._name;
        }

        if (this._description !== undefined) {
            itemListItem.description = this._description;
        }

        if (this._dimensions !== undefined) {
            itemListItem.dimensions = this._dimensions.serialize();
        }

        if (this._classification !== undefined) {
            itemListItem.classification = this._classification.serialize();
        }

        if (this._refCodes !== undefined) {
            itemListItem.refCodes = this._refCodes.serialize();
        }

        if (this._attributes !== undefined) {
            itemListItem.attributes = this._attributes;
        }

        if (this._packMeasurementUnit !== undefined) {
            itemListItem.packMeasurementUnit = this._packMeasurementUnit;
        }

        if (this._providerPrices !== undefined) {
            itemListItem.providerPrices = this._providerPrices.map((providerPrice: ItemListProviderPrice) => providerPrice.serialize());
        }

        if (this._note !== undefined) {
            itemListItem.note = this._note;
        }

        if (this._sourceType !== undefined) {
            itemListItem.sourceType = this._sourceType;
        }

        if (this._extras !== undefined) {
            itemListItem.extras = this._extras;
        }

        if (this._subItems !== undefined && this._subItems.length) {
            itemListItem.subItems = this._subItems.map((subItem: IConsolidatedItemListSubItem) => ObjectUtils.cloneObj(subItem));
        }

        if (this._availability !== undefined) {
            itemListItem.availability = this._availability.serialize();
        }

        if (this._calculationSteps !== undefined) {
            itemListItem.calculationSteps = this._calculationSteps.map((calculationStep: ItemListCalculationStep) => calculationStep.serialize());
        }

        if (this._messages !== undefined) {
            itemListItem.messages = this._messages.map((message: ItemListMessage) => message.serialize());
        }

        return itemListItem
    }
}

export interface IConsolidatedItemListItem {
    id: string;
    primaryRefCode: string;
    catalogId: string;
    itemCode?: string;
    configurationState?: IConsolidatedConfigurationState;
    name?: string;
    description?: string;
    dimensions?: IConsolidatedItemListItemDimensions;
    classification?: IConsolidatedItemClassification;
    refCodes?: IConsolidatedReferenceCodes;
    attributes?: Record<string, Array<ResolvableTypes>>;
    quantity: number;
    quantityPerPack?: number;
    packMeasurementUnit?: ItemListMeasurementUnit;
    providerPrices?: Array<IConsolidatedItemListProviderPrice>;
    note?: string;
    sourceType?: ItemListItemSourceType;
    extras?: string;
    subItems?: Array<IConsolidatedItemListSubItem>;
    availability?: IConsolidatedItemListItemAvailability;
    calculationSteps?: Array<IConsolidatedItemListCalculationStep>;
    messages?: Array<IConsolidatedItemListMessage>;
}

export interface IConsolidatedItemListSubItem {
    itemId: string;
    code?: string;
    quantityFromPack?: number;
}