export class ItemListInfoFactory {
    static create(itemListInfo: IItemListInfo): IConsolidatedItemListInfo {
        return new ItemListInfo(itemListInfo).serialize();
    }
}
export class ItemListInfo {
    protected _name?: string;
    protected _formatVersion: string;
    protected _sourceAppId?: string;
    protected _sourceAppVersion?: string;
    protected _timeStamp?: string;

    constructor(itemListInfo: IItemListInfo) {
        this._name = itemListInfo.name;
        this._formatVersion = itemListInfo.formatVersion;
        this._sourceAppId = itemListInfo.source?.applicationId;
        this._sourceAppVersion = itemListInfo.source?.applicationVersion;
        this._timeStamp = itemListInfo.source?.timeStamp;
    }

    serialize(): IConsolidatedItemListInfo {
        const itemListInfo: IConsolidatedItemListInfo = {
            formatVersion: this._formatVersion
        };

        if (this._name !== undefined) {
            itemListInfo.name = this._name;
        }

        if (this._sourceAppId !== undefined) {
            itemListInfo.applicationId = this._sourceAppId;
        }

        if (this._sourceAppVersion !== undefined) {
            itemListInfo.applicationVersion = this._sourceAppVersion;
        }

        if (this._timeStamp !== undefined) {
            itemListInfo.timeStamp = this._timeStamp;
        }

        return itemListInfo
    }
}

export interface IConsolidatedItemListInfo {
    name?: string;
    formatVersion: string;
    applicationId?: string;
    applicationVersion?: string;
    timeStamp?: string;
}