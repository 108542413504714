import {
    Accessor,
    Root,
    Document,
    Buffer,
    Scene,
    Node
} from "@gltf-transform/core";
import { vec3 } from "gl-matrix";

export type Triangle = [vec3, vec3, vec3];
export type LineSegment3D = [vec3, vec3];

export type X_AXIS = 0;
export type Y_AXIS = 1;
export type Z_AXIS = 2;

export const X_AXIS: X_AXIS = 0;
export const Y_AXIS: Y_AXIS = 1;
export const Z_AXIS: Z_AXIS = 2;

export class GeometryUtils {
    /**
     * method to alter input indices array and reverse the triangle winding order
     * @param indices
     */
    static reverseWindingOrder(indices: Array<number>): void {
        // change triangle clock
        for (let i: number = 0; i < indices.length; i += 3) {
            let temp: number = indices[i];
            indices[i] = indices[i + 1];
            indices[i + 1] = temp;
        }
    }

    static extract2DPointsFrom3DPoints(vertices: Float32Array, axis: [number, number]): Float32Array {
        let points2D: Array<number> = [];

        for (let i: number = 0; i < vertices.length; i += 3) {
            points2D.push(vertices[i + axis[0]]);
            points2D.push(vertices[i + axis[1]]);
        }

        return new Float32Array(points2D);
    }

    static clampVertices(vertices: Float32Array, zeroAxis?: X_AXIS | Y_AXIS | Z_AXIS, minimum?: Vector3, maximum?: Vector3): Float32Array {
        let clampVertices: Float32Array = new Float32Array(vertices.length);

        for (let i: number = 0; i < vertices.length; i += 3) {
            if (minimum && maximum) {
                clampVertices[i] = Math.max(Math.min(vertices[i], maximum[0]), minimum[0]);
                clampVertices[i + 1] = Math.max(Math.min(vertices[i + 1], maximum[1]), minimum[1]);
                clampVertices[i + 2] = Math.max(Math.min(vertices[i + 2], maximum[2]), minimum[2]);
            } else {
                clampVertices.set(vertices.slice(i, i + 2), i);
                // clampVertices[i] = vertices[i];
                // clampVertices[i + 1] = vertices[i + 1];
                // clampVertices[i + 2] = vertices[i + 2];
            }

            if (zeroAxis !== undefined) {
                clampVertices[i + zeroAxis] = 0;
            }
        }

        return clampVertices;
    }

    static getTrianglesFromIndices(indices: Uint16Array, vertices: Float32Array): Array<Triangle> {
        let triangles: Array<Triangle> = [];

        for (let i: number = 0; i < indices.length; i += 3) {
            let p1: vec3 = vec3.fromValues(...Array.from(vertices.slice(indices[i] * 3, (indices[i] * 3) + 3)) as [number, number, number]),
                p2: vec3 = vec3.fromValues(...Array.from(vertices.slice(indices[i + 1] * 3, (indices[i + 1] * 3) + 3)) as [number, number, number]),
                p3: vec3 = vec3.fromValues(...Array.from(vertices.slice(indices[i + 2] * 3, (indices[i + 2] * 3) + 3)) as [number, number, number]);

            triangles.push([p1, p2, p3]);
        }

        return triangles;
    }

    static orderTriangles(triangles: Array<Triangle>): Array<Triangle> {
        let trianglesToOrder: Array<Triangle> = triangles.slice();
        let orderedTriangles: Array<Triangle> = [];

        // iterate over triangles
        let currentTriangle: Triangle = trianglesToOrder.shift() as Triangle;

        while (trianglesToOrder.length > 0) {
            orderedTriangles.push(currentTriangle);

            // find next triangle
            let foundTriangleIndex: number = trianglesToOrder.findIndex((triangle: Triangle) => {
                let touchPoints: number = 0;

                currentTriangle.forEach((currentVertex: vec3) => {
                    if (triangle.some((triangleVertex: vec3) => vec3.equals(currentVertex, triangleVertex))) {
                        touchPoints++;
                    }
                });

                return touchPoints == 2;
            });

            if (foundTriangleIndex === -1 && trianglesToOrder.length > 0) {
                throw new Error("Invalid triangulation");
            }

            currentTriangle = trianglesToOrder.splice(foundTriangleIndex, 1)[0];
            if (trianglesToOrder.length === 0) {
                orderedTriangles.push(currentTriangle);
            }
        }

        return orderedTriangles;
    }

    static getTrianglesOuterLineSegments(triangles: Array<Triangle>): Array<LineSegment3D> {
        let triangleLineSegments: Array<LineSegment3D> = [];

        let lineTracker: Map<string, LineSegment3D> = new Map();

        triangles.forEach((triangle: Triangle) => {
            triangle.forEach((vertex: vec3, idx: number) => {
                let nextVertex: vec3 = triangle[(idx + 1) % 3];
                let segment: LineSegment3D = [vertex, nextVertex];

                let key: string = segment.map((v: vec3) => vec3.str(v)).join(":");
                let matchKey: string | undefined;

                if (lineTracker.has(key)) {
                    matchKey = key;
                } else {
                    key = segment.slice().reverse().map((v: vec3) => vec3.str(v)).join(":");
                    if (lineTracker.has(key)) {
                        matchKey = key;
                    }
                }

                if (matchKey) {
                    let segmentToDel: LineSegment3D = lineTracker.get(matchKey) as LineSegment3D;
                    lineTracker.delete(matchKey);
                    let index: number = triangleLineSegments.indexOf(segmentToDel);
                    if (index > -1) {
                        triangleLineSegments.splice(index, 1);
                    }
                } else {
                    lineTracker.set(key, segment);
                    triangleLineSegments.push(segment);
                }
            });
        });

        return triangleLineSegments;
    }

    static chainLineSegments(lineSegments: Array<LineSegment3D>): Array<LineSegment3D> {
        let orderedLineSegments: Array<LineSegment3D> = [];
        let currentSegment: LineSegment3D | undefined;

        currentSegment = lineSegments.shift() as LineSegment3D;
        orderedLineSegments.push(currentSegment);

        while (lineSegments.length > 0) {
            if (!currentSegment) break;

            // find next segment
            let nextIdx: number = lineSegments.findIndex((segment: LineSegment3D) => {
                return vec3.equals(segment[0], currentSegment![1]);
            });

            if (nextIdx > -1) {
                currentSegment = lineSegments.splice(nextIdx, 1)[0];
                orderedLineSegments.push(currentSegment);
            } else if (lineSegments.length > 0) {
                throw new Error("Unable to find connecting segment");
            }
        }

        return orderedLineSegments;
    }

    static mergeGLTFDocuments(outDocument: Document, withDocument: Document, mergeScenes: boolean = false): Document {
        outDocument.merge(withDocument);

        let docRoot: Root = outDocument.getRoot();
        const mainBuffer: Buffer = docRoot.listBuffers()[0];
        if (mergeScenes) {
            let scenes: Array<Scene> = docRoot.listScenes(),
                rootScene: Scene = scenes[0];

            docRoot.setDefaultScene(rootScene);
            if (scenes.length > 1) {
                for (let i: number = 1; i < scenes.length; i++) {
                    scenes[i]
                        .listChildren()
                        .forEach((node: Node) => rootScene.addChild(node));
                    scenes[i].dispose();
                }
            }
        }

        docRoot.listAccessors().forEach((accessor: Accessor) => accessor.setBuffer(mainBuffer));
        docRoot.listBuffers().forEach((buff: Buffer, index: number) => index > 0 && buff.dispose());

        return outDocument;
    }

    // Search the insertionOffsets structure for a matching context. If one isn't found or hasn't been specified, return the default.
    static getInsertionOffset(insertionOffset: IInsertionOffsets, locationType: LocationType, installationType: string = ""): Vector3Str {
        let insertionOffsets: Array<IInsertionOffsets> = [insertionOffset];

        if (insertionOffset?.others) {
            insertionOffsets.push(...Object.values(insertionOffset.others));
        }

        return this.findInInsertionOffsets(insertionOffsets, locationType, installationType);
    }

    // Search the insertionOffsets structure for a matching context. If one isn't found or hasn't been specified, return the default.
    static findInInsertionOffsets(insertionOffsets: Array<IInsertionOffsets>, locationType: LocationType, installationType: string = ""): Vector3Str {
        let offset: Vector3Str | undefined;

        insertionOffsets.some((otherOffset: IInsertionOffset) => {
            offset = GeometryUtils.getInsertionOffsetForContext(otherOffset, locationType, installationType);
            return Boolean(offset);
        });

        // If any offset does not provide a locationType, it is considered as "asSubItem"
        // At this point, if offset is still undefined, that means there is no offset` for the given locationType and no default offset is available,
        // so return ["0", "0", "0"]
        return offset ?? ["0", "0", "0"];
    }

    static getInsertionOffsetForContext(offset: IInsertionOffset, locationType: LocationType, installationType: string = ""): Vector3Str | undefined {
        let insertionOffset: Vector3Str | undefined,
            // If InsertionOffset context is not provided OR context.locationType is not provided,
            // the default locationType is "asSubItem"
            locationTypeMatchFound: boolean = Boolean(
                (locationType === "asSubItem" && (!offset.context || offset.context.locationType.length === 0)) ||
                offset.context?.locationType.includes(locationType)
            );

        if (offset.position &&
            locationTypeMatchFound &&
            (!offset.context || !installationType || installationType === offset.context.installationType)
        ) {
            insertionOffset = offset.position;
        }

        return insertionOffset;
    }
}