import React, { useState, useEffect, useRef, MutableRefObject, useContext } from 'react';
import { ICatalogPlus, catalogStatus, CatalogMenuItem, catalogMenus } from 'Interfaces';
import { Helpers } from 'Helpers';
import { CatalogGallery } from './catalogGallery/CatalogGallery';
import { SideNavigation } from './navigation/side/SideNavigation';
import { CatalogSettingsAction } from './catalogSettingsAction/CatalogSettingsAction';
import { FilterInput } from 'filterInput/FilterInput';
import { DropDown } from 'dropDown/DropDown';
import { EntryLabel } from 'entryLabel/EntryLabel';
import { ContextualHelp } from 'contextualHelp/ContextualHelp';
import { useContextualHelp } from 'hooks/useContextualHelp';
import { ContextualHelpContext } from 'contextualHelp/contextualHelpContext';
import { ContextualHelpStepsIndex } from 'contextualHelp/ContextualHelpSteps';
import { useNavigatorStatus } from 'react-navigator-status';
import { Tooltip } from 'react-tooltip';
import { strings } from 'strings';
import './catalogSettings.scss';

export type CatalogSettingsProps = {
    baseUrl: string,
    locale: string,
    token: string,
    theme: string,
    hiddens: Array<string>,
    showMaterialCatalog: boolean,
    enableContextualHelp: boolean;
}
export function CatalogSettings(props: CatalogSettingsProps) {
    let displayStatusList: JSX.Element | boolean = false,
        displayManufacturerList: JSX.Element | boolean = false;
    
     
    const isOnline = useNavigatorStatus(),
        apiHandleRef: MutableRefObject<ICiCAPI | undefined> = useRef(),
        inputRef: MutableRefObject<HTMLInputElement | null> = useRef(null),   
        needToFilter: MutableRefObject<boolean> = useRef(false),   
        { showContextualHelp } = useContext(ContextualHelpContext),     
        [filterCatalog, setFilterCatalog] = useState<string>(''),
        [activeCatalogType, setActiveCatalogType] = useState<CatalogMenuItem>(catalogMenus[0]),
        [isLoadingCatalogs, setLoadingCatalogs] = useState<boolean>(false),
        [allCatalogs, setAllCatalogs] = useState<Array<ICatalogPlus>>([]),
        [manufacturerList, setManufacturerList] = useState<Array<string>>([]),
        [catalogStatusFilter, setCatalogStatusFilter] = useState(catalogStatus[0]),
        [manufacturerFilter, setManufacturerFilter] = useState<string>(strings['catalog.type.all.manufacturers']),
        [isCatalogStatusFilterOpened, setCatalogStatusFilterOpened] = useState<boolean>(false),
        [isManufacturerFilterOpened, setManufacturerFilterOpened] = useState<boolean>(false),
        [hiddenCatalogs, setHiddenCatalogs] = useState<Array<string> | undefined>(props.hiddens),
        [filterManuf, setFilterManuf] = useState<string>(''),
        [startFresh, setStartFresh] = useState<number>(0),
        hiddenCatalogsRef: MutableRefObject<Array<string>> = useRef(props.hiddens),

        {
            contextualHelpEnable,
            toggleContextualHelp,
            showCtxlHelp,
            ctxlHelpStepIndex,
            ctxlHelpIndex,
            hideContextualHelp
        } = useContextualHelp(props.enableContextualHelp),
        
        handleChange = (filterInput: string) => {
            setFilterCatalog(filterInput);
            setStartFresh(startFresh + 1);
        },
        handleCatalogAction = () => {

        },        
        handleFilterManufChange = (filterInput: string) => {
            setFilterManuf(filterInput.toLowerCase());
            setStartFresh(startFresh + 1);
        },
        handleCatalogStatusFilterClick = (isCatalogStatusOpened: boolean) => {
            showContextualHelp(undefined);
            setCatalogStatusFilterOpened(isCatalogStatusOpened);
        },
        handleCatalogFilterChanged = (catalogStatus: any) => {
            setCatalogStatusFilter(catalogStatus);
            setStartFresh(startFresh + 1);
            setCatalogStatusFilterOpened(false);
        },
        handleManufacturerClick = (isOpened: boolean) => {
            showContextualHelp(undefined);
            setManufacturerFilterOpened(isOpened);
        },
        handleManufacturerChanged = (manufacturer: string) => {
            setManufacturerFilter(manufacturer);
            setStartFresh(startFresh + 1);
            setManufacturerFilterOpened(false);
        },        
        handleCatalogVisibilityChanged = (changeCatalog: ICatalogPlus) => {
            let updateCatalog: ICatalogPlus | undefined = allCatalogs.find((catalog: ICatalogPlus) => catalog.id === changeCatalog.id),
                updateHiddenCatalogs: Array<string> = [...hiddenCatalogs || []];

            if (updateCatalog) {
                let hideCatalog: boolean = !(updateCatalog.isHidden);

                updateCatalog.isHidden = hideCatalog;

                if (hideCatalog && !hiddenCatalogs?.includes(changeCatalog.code)) {
                    updateHiddenCatalogs.push(changeCatalog.code);
                } else if (!hideCatalog) {
                    updateHiddenCatalogs = hiddenCatalogs?.filter((catalogCode: string) => catalogCode !== changeCatalog.code) || [];
                }

                setHiddenCatalogs(updateHiddenCatalogs);
                hiddenCatalogsRef.current = updateHiddenCatalogs;

                setAllCatalogs([...allCatalogs]);
                //Helpers.saveUserPref('hiddenCatalogs', JSON.stringify(prefHiddenCatalogs));

                if (window && window.opener) {
                    //let hiddenCatalogs = JSON.stringify(hiddenCatalogs);
                    //window.opener.postMessage({ message: 'changing', hiddenCatalogs: hiddenCatalogs }, '*');
                }
            }
        },
        fetchCatalogFunc = async () => {
            if (!isLoadingCatalogs) {
                setLoadingCatalogs(true);
                let allRootCatalogs: Array<ICatalogPlus> = await Helpers.fetchCatalogsWithRestricted(apiHandleRef.current, true, props.token, hiddenCatalogs || []);

                setAllCatalogs([...allRootCatalogs]);
                Helpers.loadCompanyCatalogsMap(allRootCatalogs);
                setManufacturerList(Helpers.extractManufacturerListFromCatalogs(allRootCatalogs));
                setLoadingCatalogs(false);
            }
        },
        filterCatalogs = () => {
            let updatedCatalogList: Array<ICatalogPlus> = Helpers.filterAllCatalogs(allCatalogs, filterCatalog.toLowerCase(), catalogStatusFilter[0], manufacturerFilter, hiddenCatalogs || [], activeCatalogType.path);
            setAllCatalogs([...updatedCatalogList]);
        },
        handleApplyChanged = (message: string) => {
            let stringedHiddenCatalogs: string = JSON.stringify(hiddenCatalogsRef.current);
            if (window && window.opener) { //web 
                //let updatedHiddenCatalogs = message === 'update' ? JSON.stringify(hiddenCatalogs) : null;
                window.opener.postMessage({ message: message, hiddenCatalogs: stringedHiddenCatalogs }, '*');
                window.close();
            } else {
                const CONTENT_BROWSER_WINDOW_CODE: string = "content-browser";
                let payload: Record<string, any> = {
                    cmd: "request-window-update",
                    name: CONTENT_BROWSER_WINDOW_CODE,
                    message: message,
                    hiddenCatalogs: stringedHiddenCatalogs
                };

                window.postMessage(payload);
                setTimeout(() => window.close());
            }
        },
        handleCatalogTypeChanged = (menuItem: CatalogMenuItem) => {
            setActiveCatalogType(menuItem);
            setStartFresh(startFresh + 1);
        },
        handleRequestCatalogAccess = async (catalogRequested: ICatalogPlus) => {
            let accessStatus: RequestAccessStatus | string | undefined = await Helpers.requestCatalogAccess(apiHandleRef.current, catalogRequested),
                updateCatalog: ICatalogPlus | undefined = allCatalogs.find((catalog: ICatalogPlus) => catalog.id === catalogRequested.id);

            if (updateCatalog && accessStatus as RequestAccessStatus) {
                updateCatalog.accessStatus = accessStatus;
            }
            let updatedCatalogList: Array<ICatalogPlus> = [...allCatalogs];
            setAllCatalogs(updatedCatalogList);
        },
           
        localOverride = async () => {
            const actualLocale: string = Helpers.getUserPref('locale');
            if (!actualLocale || actualLocale !== props.locale) {
                //await CiCAPI.setExecutionContext({"locale": props.locale});
                Helpers.saveUserPref('locale', props.locale);
                window.location.reload();
            }
        },
        handleContextualHelpToggled = (isEnable: boolean) => {
            Helpers.saveUserPref('enable_contextualHelp', isEnable);
            toggleContextualHelp(isEnable);

            if (isEnable) {
                const localStorageSteps = JSON.parse(localStorage.getItem('contextualHelpSteps') || '[]');

                const extractedCtxlHelpSteps = localStorageSteps.map((step: { id: string, shownOverlay: boolean }) => ({
                    id: step.id,
                    shownOverlay: false,
                }));

                localStorage.setItem('contextualHelpSteps', JSON.stringify(extractedCtxlHelpSteps));
                hideContextualHelp();
            }
        },
        handleSearchMouseEnter = () => {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_SEARCH, inputRef);
        },
        handleStatusMouseEnter = (buttonElement: React.RefObject<HTMLButtonElement>) => {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_STATUS, buttonElement);

        },
        handleManufacturerMouseEnter = (buttonElement: React.RefObject<HTMLButtonElement>) => {
            showContextualHelp(ContextualHelpStepsIndex.CATALOG_MANUFACTURER, buttonElement);
        };

    useEffect(() => {
        if (hiddenCatalogs === undefined) {
            setHiddenCatalogs(props.hiddens);
            hiddenCatalogsRef.current = props.hiddens;
        }

        let isClosing: boolean = false;
        apiHandleRef.current = CiCAPI.getAPIHandle("catalog-settings");
        document.body.onmousedown = e => { if (e.button === 1) return false; }; //disable middle click

        localOverride();
        let beforeUnloadFunc = (event: any) => {
            if (!isClosing) {
                if (window && window.opener) {
                    handleApplyChanged('update');
                } else {
                    handleApplyChanged('update');
                    event.returnValue = false;
                }
            }

            isClosing = true;
        };
        window.addEventListener("beforeunload", beforeUnloadFunc);

        return () => {
            window.removeEventListener("beforeunload", beforeUnloadFunc);
        }
    }, []);

    useEffect(() => {
        if (allCatalogs.length > 0 && needToFilter.current === true) {
            needToFilter.current = false;       
            filterCatalogs();
        }
    }, [allCatalogs]);
    
    useEffect(() => {
        if (isLoadingCatalogs) {
           needToFilter.current = true; 
        } else if (hiddenCatalogs !== undefined && allCatalogs.length > 0) {
            filterCatalogs();  
        } else {
            fetchCatalogFunc(); // initial fetch   
        }
    }, [catalogStatusFilter, manufacturerFilter, filterCatalog, manufacturerFilter, hiddenCatalogs, activeCatalogType.path]);

    if (isCatalogStatusFilterOpened) {
        displayStatusList = (
            <div className={`filter-catalog-status__container  `}>
                {catalogStatus.map((catalogStatus: any, index: number) => {
                    let isSelected = catalogStatusFilter[0] === catalogStatus[0] ? true : false;

                    return (
                        <EntryLabel
                            key={index}
                            objectType={catalogStatus}
                            label={catalogStatus[1]}
                            isSelected={isSelected}                            
                            isDisabled={false}
                            onLabelClicked={handleCatalogFilterChanged}
                        />
                    )
                })}
            </div>
        );
    }
    if (isManufacturerFilterOpened) {
        let filteredManufList: Array<string> = [];
        if (filterManuf) {
            manufacturerList.forEach((manufName: string, index: number) => {
                if (manufName.toLowerCase().includes(filterManuf)) {
                    filteredManufList.push(manufName);
                }
            });
        } else {
            filteredManufList = manufacturerList;
        }

        displayManufacturerList = (
            <div className={`filter-manufacturer__container  `}>
                <FilterInput
                    inputRef={inputRef}
                    containerClassName='search-manuf__container'
                    searchLabel={strings['manufacturers.filter.search']}
                    resetLabel={strings['catalogs.filter.reset']}
                    dataFor={'catalogs'}
                    dataTip={strings['manufacturers.filter.search']}
                    filterValue={filterManuf}
                    debounce={true}
                    minLenght={2}
                    searchIcon={true}
                    onChange={handleFilterManufChange}
                />
                <div
                    className={`filter-manufacturer__container-list nice-scrolling  `}
                >
                    {!filteredManufList.length ? (
                        <span className=" manufacturer_no-result  ">
                            {strings['manufacturers.result.noManufacturers']}
                        </span>
                    ) : (
                        filteredManufList.map(
                            (manufacturer: string, index: number) => {
                                let isSelected =
                                    manufacturerFilter === manufacturer
                                        ? true
                                        : false;

                                return (
                                    <EntryLabel
                                        key={index}
                                        objectType={manufacturer}
                                        label={manufacturer}
                                        isSelected={isSelected}                                        
                                        isDisabled={false}
                                        onLabelClicked={handleManufacturerChanged}
                                    />
                                );
                            }
                        )
                    )}
                </div>
            </div>
        );
    }

    return (
        <div className={`catalog-settings__container  `}>
            <SideNavigation
                catalogMenus={props.showMaterialCatalog ? catalogMenus : catalogMenus.filter((menu: CatalogMenuItem) => menu.path !== 'materials')}
                activeFilter={activeCatalogType.path}
                theme={props.theme}
                baseUrl={props.baseUrl}
                tabIndex={1}      
                contextualHelpEnabled={contextualHelpEnable}          
                onFilterChanged={handleCatalogTypeChanged}
            />
            
            <div className={`catalog-settings__main-container  `}>                

                <div className={`catalog-settings__content-container   `}>
                    <div className={`catalog-settings__content-title  `}>
                        {activeCatalogType.title}
                    </div>
                    <div className='catalog-settings__title-and-filter'>
                        
                        <div className={`catalog-settings__all-filters`}>
                            <FilterInput
                                inputRef={inputRef}
                                containerClassName={`catalog-settings__filter-container step-catalog-search`}
                                searchLabel={strings['search.catalog.findLabel']}
                                resetLabel={strings['search.catalog.resetLabel']}
                                headerLabel={strings['search.catalog.header']}
                                dataTip={strings['search.catalog.findLabel']}
                                dataFor='catalogs'   
                                tabIndex={10}                             
                                filterValue={filterCatalog}
                                debounce={true}
                                minLenght={2}
                                onChange={handleChange}
                                onMouseEnter={handleSearchMouseEnter}
                                //onFocus={() => showContextualHelp(undefined)}
                            />
                            <DropDown
                                key={'catalogStatus'}
                                id={'catalogStatus'}
                                label={`${catalogStatusFilter[1]}`}
                                headerLabel={strings['catalog.status.header']}                                
                                tooltip={strings['catalog.status.tooltip']}
                                isOpened={isCatalogStatusFilterOpened}
                                flexGrow={true}
                                width='33%'
                                maxWidth='240px'
                                height={'34px'}
                                dataFor='catalogs'
                                topMargin='0'
                                sideMargin='0'
                                tabIndex={15}
                                targetClassName='step-catalog-status'
                                onOpenedClick={handleCatalogStatusFilterClick}
                                onMouseEnter={handleStatusMouseEnter}
                            >
                                {displayStatusList}
                            </DropDown>
                            <DropDown
                                key={'manufacturer'}
                                id={'manufacturer'}
                                label={manufacturerFilter}
                                headerLabel={strings['manufacturers.header']}                                
                                flexGrow={true}
                                width='33%'
                                maxWidth='240px'
                                height={'34px'}                                
                                dataFor='catalogs'
                                topMargin='0'
                                sideMargin='0'
                                tabIndex={20}
                                tooltip={strings['catalog.manufacturer.tooltip']}
                                isOpened={isManufacturerFilterOpened}
                                targetClassName='step-catalog-manufacturer'
                                onOpenedClick={handleManufacturerClick}
                                onMouseEnter={handleManufacturerMouseEnter}
                            >
                                {displayManufacturerList}
                            </DropDown>
                        </div>
                    </div>
                    <CatalogGallery
                        visibleCatalogs={allCatalogs.filter((catalog: ICatalogPlus) => catalog.isVisible === true)}
                        hiddenCatalogCodes={hiddenCatalogs || []}
                        isLoading={isLoadingCatalogs}                        
                        isOnline={isOnline}
                        theme={'cy-theme' || 'orange'}
                        startFresh={startFresh}
                        baseUrl={props.baseUrl}
                        tabIndex={100}
                        onCatalogAction={handleCatalogAction}
                        onCatalogVisibilityChanged={handleCatalogVisibilityChanged}
                        onRequestCatalogAccess={handleRequestCatalogAccess}
                    />
                </div>
                <CatalogSettingsAction
                    nbrCatalogs={Helpers.nbrVisibleCatalogs(allCatalogs)}
                    isLoading={isLoadingCatalogs}                    
                    disabled={false}
                    tabIndex={1001}
                    onApplyChanged={handleApplyChanged}
                />
            </div>
            
            {contextualHelpEnable && showCtxlHelp 
                ?
                <ContextualHelp
                    hideContextualHelp={hideContextualHelp}
                    ctxlHelpIndex={ctxlHelpIndex}
                    ctxlHelpStepIndex={ctxlHelpStepIndex}
                    tooltipId='catalogs'
                    handleContextualHelpToggled={handleContextualHelpToggled}
                />
                :
                <Tooltip className='tooltip-width' id='catalogs' />
            }
        </div>
    )
}


