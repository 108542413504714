export class ItemListItemDimensionsFactory {
    static create(itemListItemDimensions: IItemListItemDimensions): IConsolidatedItemListItemDimensions {
        return new ItemListItemDimensions(itemListItemDimensions).serialize();
    }
}

export class ItemListItemDimensions {
    protected _width?: number;
    protected _height?: number;
    protected _depth?: number;
    protected _unit?: ItemListMeasurementUnit

    constructor(itemListItemDimensions: IItemListItemDimensions) {
        this._width = itemListItemDimensions.width;
        this._height = itemListItemDimensions.height;
        this._depth = itemListItemDimensions.depth;
        this._unit = itemListItemDimensions.unit;
    }

    serialize(): IConsolidatedItemListItemDimensions {
        return {
            width: this._width,
            height: this._height,
            depth: this._depth,
            unit: this._unit
        };
    }
}

export interface IConsolidatedItemListItemDimensions {
    width?: number;
    height?: number;
    depth?: number;
    unit?: string;
}