import { ImageRef, IConsolidatedImageRef } from "./ImageRef";

export class ImagesFactory {
    static create(images: IImages): IConsolidatedImages {
        return new Images(images).serialize();
    }
}

export class Images {
    protected _main: ImageRef;
    protected _others?: Array<ImageRef>;

    constructor(images: IImages) {
        this._main = new ImageRef(images.main);
        this._others = images.others?.map((image) => new ImageRef(image));
    }

    serialize(): IConsolidatedImages {
        const images: IConsolidatedImages = {
            main: this._main.serialize(),
        };

        if (this._others !== undefined) {
            images.others = this._others.map((image: ImageRef) => image.serialize());
        }

        return images;
    }
}

export interface IConsolidatedImages {
    main: IConsolidatedImageRef;
    others?: Array<IConsolidatedImageRef>;
}