import { IConsolidatedItemListProviderTax, ItemListProviderTax } from "./ItemListProviderTax";

export class ItemListProviderPriceFactory {
    static create(itemListProviderPrice: IItemListProviderPrice): IConsolidatedItemListProviderPrice {
        return new ItemListProviderPrice(itemListProviderPrice).serialize();
    }
}

export class ItemListProviderPrice {
    protected _totalAmount: number;
    protected _totalIncludesTaxes: boolean;
    protected _priceListCode: string;
    protected _currencyConvention: CurrencyConvention;
    protected _currencyCode?: string;
    protected _unitAmount?: number;
    protected _validFrom?: string;
    protected _validUntil?: string;
    protected _taxes?: Array<ItemListProviderTax>;

    constructor(itemListProviderPrice: IItemListProviderPrice) {
        this._totalAmount = itemListProviderPrice.totalAmount;
        this._totalIncludesTaxes = itemListProviderPrice.totalIncludesTaxes;
        this._priceListCode = itemListProviderPrice.priceListCode;
        this._currencyConvention = itemListProviderPrice.currencyConvention;
        this._currencyCode = itemListProviderPrice.currencyCode;
        this._unitAmount = itemListProviderPrice.unitAmount;
        this._validFrom = itemListProviderPrice.validFrom;
        this._validUntil = itemListProviderPrice.validUntil;
        this._taxes = itemListProviderPrice.taxes?.map((tax: IItemListProviderTax) => new ItemListProviderTax(tax));
    }

    serialize(): IConsolidatedItemListProviderPrice {
        const itemListProviderPrice: IConsolidatedItemListProviderPrice = {
            totalAmount: this._totalAmount,
            totalIncludesTaxes: this._totalIncludesTaxes,
            priceListCode: this._priceListCode,
            currencyConvention: this._currencyConvention
        };

        if (this._currencyCode !== undefined) {
            itemListProviderPrice.currencyCode = this._currencyCode;
        }

        if (this._unitAmount !== undefined) {
            itemListProviderPrice.unitAmount = this._unitAmount;
        }

        if (this._validFrom !== undefined) {
            itemListProviderPrice.validFrom = this._validFrom;
        }

        if (this._validUntil !== undefined) {
            itemListProviderPrice.validUntil = this._validUntil;
        }

        if (this._taxes !== undefined) {
            itemListProviderPrice.taxes = this._taxes.map((tax: ItemListProviderTax) => tax.serialize());
        }

        return itemListProviderPrice;
    }
}

export interface IConsolidatedItemListProviderPrice {
    totalAmount: number;
    totalIncludesTaxes: boolean;
    priceListCode: string;
    currencyConvention: CurrencyConvention;
    currencyCode?: string;
    unitAmount?: number;
    validFrom?: string;
    validUntil?: string;
    taxes?: Array<IConsolidatedItemListProviderTax>;
}