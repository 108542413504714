import { FeatureState, IConsolidatedFeatureState } from "./ConfigurationState";

export class ConfigurationChangeFactory {
    static create(configurationChange: IConfigurationChange): IConsolidatedConfigurationChange {
        return new ConfigurationChange(configurationChange).serialize();
    }
}

export class ConfigurationChange {
    protected _state: FeatureState;
    protected _context: CONFIGURATION_CONTEXT;
    protected _contextId: string;
    protected _type: CHANGE_REASON;

    constructor(configurationChange: IConfigurationChange) {
        this._state = new FeatureState(configurationChange.state);
        this._context = configurationChange.context;
        this._contextId = configurationChange.contextId;
        this._type = configurationChange.type;
    }

    serialize(): IConsolidatedConfigurationChange {
        return {
            state: this._state.serialize(),
            context: this._context,
            contextId: this._contextId,
            type: this._type
        };
    }
}

export interface IConsolidatedConfigurationChange {
    state: IConsolidatedFeatureState;
    context: CONFIGURATION_CONTEXT;
    contextId: string;
    type: CHANGE_REASON;
}