import { CompleteCatalogVersion, IConsolidatedCompleteCatalogVersion } from "./CompleteCatalogVersion";
import { ConfigurationChange, IConsolidatedConfigurationChange } from "./ConfigurationChange";
import { FeatureOptionClassification, IConsolidatedFeatureOptionClassification } from "./FeatureOptionClassification";
import { ImageRef, IConsolidatedImageRef } from "./ImageRef";

export class FeatureOptionFactory {
    static create(featureOption: IFeatureOption | IConsolidatedFeatureOption, catalogInfo?: CompleteCatalogVersion): IConsolidatedFeatureOption {
        return new FeatureOption(featureOption, catalogInfo).serialize();
    }
}

export class FeatureOption {
    protected _id: string;
    protected _code: string;
    protected _name: string;
    protected _value?: number | string;
    protected _image?: ImageRef;
    protected _sequenceFactor?: number;
    protected _invalidationReasons?: Array<ConfigurationChange>;
    protected _classification?: FeatureOptionClassification;
    protected _description?: string;
    protected _catalogInfo?: CompleteCatalogVersion;

    constructor(featureOption: IFeatureOption | IConsolidatedFeatureOption, catalogInfo?: CompleteCatalogVersion) {
        this._id = featureOption.id;
        this._code = featureOption.code;
        this._name = featureOption.name;
        this._value = featureOption.value;
        this._image = featureOption.image ? new ImageRef(featureOption.image) : undefined;
        this._sequenceFactor = featureOption.sequenceFactor;
        this._invalidationReasons = featureOption.invalidationReasons?.map((reason: IConfigurationChange) => new ConfigurationChange(reason));
        this._classification = featureOption.classification ? new FeatureOptionClassification(featureOption.classification) : undefined;
        this._description = featureOption.description;
        this._catalogInfo = catalogInfo;
    }

    get id(): string {
        return this._id;
    }

    get code(): string {
        return this._code;
    }

    get name(): string {
        return this._name;
    }

    get value(): number | string | undefined {
        return this._value;
    }

    get image(): ImageRef | undefined {
        return this._image;
    }

    get sequenceFactor(): number | undefined {
        return this._sequenceFactor;
    }

    get invalidationReasons(): Array<ConfigurationChange> | undefined {
        return this._invalidationReasons;
    }

    get classification(): FeatureOptionClassification | undefined {
        return this._classification;
    }

    get description(): string | undefined {
        return this._description;
    }

    serialize(): IConsolidatedFeatureOption {
        const featureOption: IConsolidatedFeatureOption = {
            id: this._id,
            code: this._code,
            name: this._name
        };

        if (this._value !== undefined) {
            featureOption.value = this._value;
        }

        if (this._image !== undefined) {
            featureOption.image = this._image.serialize();
        }

        if (this._sequenceFactor !== undefined) {
            featureOption.sequenceFactor = this._sequenceFactor;
        }

        if (this._invalidationReasons !== undefined) {
            featureOption.invalidationReasons = this._invalidationReasons.map((reason: ConfigurationChange) => reason.serialize());
        }

        if (this._classification !== undefined) {
            featureOption.classification = this._classification.serialize();
        }

        if (this._description !== undefined) {
            featureOption.description = this._description;
        }

        if (this._catalogInfo !== undefined) {
            featureOption.catalogInfo = this._catalogInfo.serialize();
        }

        return featureOption;
    }
}

export interface IConsolidatedFeatureOption {
    id: string;
    code: string;
    name: string;
    value?: number | string;
    image?: IConsolidatedImageRef;
    sequenceFactor?: number;
    invalidationReasons?: Array<IConsolidatedConfigurationChange>;
    classification?: IConsolidatedFeatureOptionClassification;
    description?: string;
    catalogInfo?: IConsolidatedCompleteCatalogVersion;
}