export class ItemListItemAvailabilityFactory {
    static create(itemListItemAvailability: IItemListItemAvailability): IConsolidatedItemListItemAvailability {
        return new ItemListItemAvailability(itemListItemAvailability).serialize();
    }
}

export class ItemListItemAvailability {
    protected _status?: string;
    protected _details?: string;
    protected _inventoryQuantity?: number;

    constructor(itemListItemAvailability: IItemListItemAvailability) {
        this._status = itemListItemAvailability.status;
        this._details = itemListItemAvailability.details;
        this._inventoryQuantity = itemListItemAvailability.inventoryQuantity;
    }

    serialize(): IConsolidatedItemListItemAvailability {
        const itemListItemAvailability: IConsolidatedItemListItemAvailability = {};

        if (this._status !== undefined) {
            itemListItemAvailability.status = this._status;
        }

        if (this._details !== undefined) {
            itemListItemAvailability.details = this._details;
        }

        if (this._inventoryQuantity !== undefined) {
            itemListItemAvailability.inventoryQuantity = this._inventoryQuantity;
        }

        return itemListItemAvailability;
    }
}

export interface IConsolidatedItemListItemAvailability {
    status?: string;
    details?: string;
    inventoryQuantity?: number;
}